import ActionUtility from '../../../utilities/ActionUtility';

export default class ProjectAction {
  static GET_SECTIONS_REQUEST = 'GET_SECTIONS_REQUEST';

  static GET_SECTIONS_REQUEST_FINISHED = 'GET_SECTIONS_REQUEST_FINISHED';

  static SET_SECTIONS = 'SET_SECTIONS';

  static GET_CLIENT_SECTIONS_REQUEST = 'GET_CLIENT_SECTIONS_REQUEST';

  static GET_CLIENT_SECTIONS_REQUEST_FINISHED =
    'GET_CLIENT_SECTIONS_REQUEST_FINISHED';

  static GET_PROJECTS_REQUEST = 'GET_PROJECTS_REQUEST';

  static GET_PROJECTS_REQUEST_FINISHED = 'GET_PROJECTS_REQUEST_FINISHED';

  static GET_BUILDER_INFO_REQUEST = 'GET_BUILDER_INFO_REQUEST';

  static GET_BUILDER_INFO_REQUEST_FINISHED =
    'GET_BUILDER_INFO_REQUEST_FINISHED';

  static SELECT_LAYOUT = 'SELECT_LAYOUT';

  static GET_LAYOUT_INFO_REQUEST = 'GET_LAYOUT_INFO_REQUEST';

  static GET_LAYOUT_INFO_REQUEST_FINISHED = 'GET_LAYOUT_INFO_REQUEST_FINISHED';

  static SELECT_TAB = 'SELECT_TAB';

  static SELECT_PAGE = 'SELECT_PAGE';

  static GET_PRICES_REQUEST = 'GET_PRICES_REQUEST';

  static GET_PRICES_REQUEST_FINISHED = 'GET_PRICES_REQUEST_FINISHED';

  static UPDATE_PRICES_REQUEST = 'UPDATE_PRICES_REQUEST';

  static UPDATE_PRICES_REQUEST_FINISHED = 'UPDATE_PRICES_REQUEST_FINISHED';

  static SELECT_PROJECT_REQUEST_FINISHED = 'SELECT_PROJECT_REQUEST_FINISHED';

  static STORE_INTERACTIVE_MAP_SUBSECTION_AND_PAGE =
    'STORE_INTERACTIVE_MAP_SUBSECTION_AND_PAGE';

  static SET_UNIT_INFO = 'SET_UNIT_INFO';

  static RESET_PROJECT = 'RESET_PROJECT';

  static CHANGE_CALCULATOR_PAGE_FINISHED = 'CHANGE_CALCULATOR_PAGE_FINISHED';

  static UPDATE_CALCULATOR_REQUEST_FINISHED =
    'UPDATE_CALCULATOR_REQUEST_FINISHED';

  static selectLayout(selectedLayout, selectedPage = 1, selectedTab = 0) {
    return ActionUtility.createAction(ProjectAction.SELECT_LAYOUT, {
      selectedLayout,
      selectedPage,
      selectedTab
    });
  }

  static setUnitInfo(selectedLayout, selectedPage, selectedTab) {
    return ActionUtility.createAction(ProjectAction.SET_UNIT_INFO, {
      selectedLayout,
      selectedPage,
      selectedTab
    });
  }
}
